<div class="container">
	@if (!isLoading) {
		<h2>Admin UI Login</h2>

		<form [formGroup]="devLoginForm" (ngSubmit)="onSubmit()">
			<form-field labelDirection="top-modal" label="{{ 'login.username' | translate }}">
				<input-field
					formControlName="username"
					id="js-e2e-username"
					placeholder="{{
						'common.enter.item' | translate: { item: 'login.username' | translate | lowercase }
					}}"
				/>
			</form-field>

			<form-field labelDirection="top-modal" label="{{ 'login.password' | translate }}">
				<input-field
					formControlName="password"
					id="js-e2e-password"
					type="password"
					placeholder="{{
						'common.enter.item' | translate: { item: 'login.password' | translate | lowercase }
					}}"
				/>
			</form-field>

			<button
				class="button"
				color="primary"
				id="js-e2e-login-button"
				mat-raised-button
				type="submit"
			>
				Login
			</button>
		</form>
	} @else {
		<div class="loading-container">
			<mat-progress-spinner class="progress-spinner" mode="indeterminate" />
			<mat-icon class="icon" svgIcon="ic-dolphin" />
		</div>
	}

	<div *ngIf="showFastLogins" class="fast-login">
		Fast logins are removed bacause of the privacy issues.
	</div>

	<ng-template #progressSpinner>
		<div *ngIf="showShortcuts" class="shortcuts">
			<div>S + L - show fast logins</div>
		</div>
	</ng-template>
</div>
