/**
 * Copyright (C) 2021 - 2025 Philips Domestic Appliances Holding B.V.
 * All rights are reserved.
 */

import { inject } from '@angular/core';
import { EnvironmentVariablesService } from '@infinum/ngx-nuts-and-bolts/env';
import { EnvironmentVariable } from '../../../../environments/environment-variable.enum';

export const DevLoginGuard = () => {
	const environmentVariablesService = inject(EnvironmentVariablesService);

	const loginRouteEnabled = environmentVariablesService.get(
		EnvironmentVariable.LOGIN_ROUTE_ENABLED,
	);
	return Boolean(loginRouteEnabled);
};
